import React from 'react';
import { Description, Wrapper, Title } from './styles';
import { PREDEFINED_TOOLBAR_BUTTONS } from 'components/ui/RichTextEditorV2/utils';
import { IBlockRegion, IFortniteLeaderboardBlockData } from '../utils';

type HeaderProps = {
  description: IFortniteLeaderboardBlockData['description'];
  onChangeDescriptionRaw: (descriptionRaw: string) => void;
  onChangeTitleRaw: (titleRaw: string) => void;
  region: IBlockRegion;
  title: IFortniteLeaderboardBlockData['title'];
};

const TitleComponent: React.FC<Pick<HeaderProps, 'onChangeTitleRaw' | 'title' | 'region'>> = ({ onChangeTitleRaw, title, region }) => {
  if (!title.show) {
    return null;
  }

  return (
    <Title
      blockTitleKey="ADMIN_LABEL_TITLE"
      onChange={onChangeTitleRaw}
      useRawDataV2={true}
      data={title.rawData}
      data-testid="fortniteLeaderboardTitle"
      toolbarButtons={PREDEFINED_TOOLBAR_BUTTONS.titleEditor}
      region={region}
    />
  );
};

const DescriptionComponent: React.FC<Pick<HeaderProps, 'onChangeDescriptionRaw' | 'description' | 'region'>> = ({ onChangeDescriptionRaw, description, region }) => {
  if (!description.show) {
    return null;
  }

  return (
    <Description
      blockTitleKey="ADMIN_LABEL_DESCRIPTION"
      onChange={onChangeDescriptionRaw}
      useRawDataV2={true}
      data={description.rawData}
      data-testid="fortniteLeaderboardDescription"
      toolbarButtons={PREDEFINED_TOOLBAR_BUTTONS.bodyEditor}
      region={region}
    />
  );
};

const Header: React.FC<HeaderProps> = ({ title, onChangeDescriptionRaw, description, onChangeTitleRaw, region }) => {
  if (!title.show && !description.show) {
    return null;
  }

  return (
    <Wrapper region={region}>
      <TitleComponent onChangeTitleRaw={onChangeTitleRaw} title={title} region={region} />
      <DescriptionComponent onChangeDescriptionRaw={onChangeDescriptionRaw} description={description} region={region} />
    </Wrapper>
  );
};

export default React.memo(Header);
