import React from 'react';
import styled from 'styled-components';
import TranslatedText from 'components/i18n/TranslatedText';
import { TITLE_FONT, SINGLE_LINE_ELLIPSIS } from 'style/mixins';
import { SURFACE_3 } from 'style/constants';
import { bw, lighten } from 'colors';

interface PanelTitleProps {
  className: string;
  title: string;
}

const Leaderboard = styled.div`
  background: ${props => lighten(SURFACE_3(props), 0.25)};
  padding: 6px 0;
  ${TITLE_FONT};
`;

const TitleContainer = styled.div`
  transform: skew(340deg);
  width: 92%;
  margin: auto;
  background: ${props => lighten(SURFACE_3(props), 0.6)};
`;

const Title = styled.div`
  transform: skew(-340deg);
  font-style: normal;
  font-size: 14.5px;
  line-height: 11px;
  color: ${props => bw(lighten(SURFACE_3(props), 0.6))};
  display: flex;
  justify-content: center;
  padding: 8px 0;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  padding: 0 10px;
  &, & span {
    ${SINGLE_LINE_ELLIPSIS}
  }
`;

export default function PanelTitle({ className, title }: PanelTitleProps) {
  return (
    <Leaderboard>
      <TitleContainer className={className}>
        <Title>
          <TranslatedText
            fallback={title}
            stringKey="FORTNITE_LEADERBOARD_TITLE"
          />
        </Title>
      </TitleContainer>
    </Leaderboard>
  );
}
