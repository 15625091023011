import React, { PropsWithChildren } from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { GameType, IBlockRegion, IFortniteLeaderboardBlockData, LeaderboardType } from '../utils';
import { Section, SectionTitle } from 'components/admin-bridge/EditorModal/ModalComponents/Section';
import {
  UploadZoneWrapper,
  DesktopBackgroundUploadZone,
  MobileBackgroundUploadZone,
  StyledLabel,
  OptionToggle,
  OptionsToggle,
  BlockPanelSettingsWrapper,
  BlockPanelSettingsTitle,
  ToggleWrapper,
} from './styles';
import { Checkbox } from 'components/admin2/ui/Checkbox';
import PageBlockSettings from 'components/admin-bridge/PageBlockSettings';
import { useAdminTranslation } from 'hooks/use-translation';
import usePageBlock from 'hooks/use-page-block';
import { StyledDropdown } from '../styles';
import useFilters from '../Leaderboard/Context/use-filters';
import BackgroundColorOptions from 'components/admin-bridge/EditorModal/ModalComponents/BackgroundColorOptions';
import usePageBlockBackgroundColorOptions from 'components/admin-bridge/EditorModal/ModalComponents/BackgroundColorOptions/use-page-block-background-color-options';
import ToggleSwitch from 'components/admin2/ui/ToggleSwitch';
import hash from 'json-stable-stringify';

interface IBlockSettingsProps {
  isOpen?: boolean;
  item: IFortniteLeaderboardBlockData;
  onChange: (item: IFortniteLeaderboardBlockData) => void;
  onClose?: () => void;
  region: IBlockRegion;
}

type EditableStringPaths = (
  'defaultFilters.session' |
  'defaultFilters' |
  'background.image.desktop' |
  'background.image.mobile' |
  'background.image.show' |
  'title.show' |
  'description.show'
);

type WrapperProps = PropsWithChildren & { isOpen: boolean; onClose: () => void; region: IBlockRegion; };

const Wrapper: React.FC<WrapperProps> = ({ region, children, isOpen, onClose }) => {
  const { t } = useAdminTranslation();

  if (region === 'panel') {
    return (
      <BlockPanelSettingsWrapper>
        <BlockPanelSettingsTitle>{t('ADMIN_LABEL_FORTNITE_LEADERBOARD_SETTINGS')}</BlockPanelSettingsTitle>
        {children}
      </BlockPanelSettingsWrapper>
    );
  }

  return (
    <PageBlockSettings
      isOpen={isOpen}
      titleKey="ADMIN_LABEL_FORTNITE_LEADERBOARD_SETTINGS"
      onClose={onClose}
    >
      {children}
    </PageBlockSettings>
  );
};

const BlockSettings: React.FC<IBlockSettingsProps> = ({ item, onChange, isOpen = true, onClose, region }) => {
  const handleClose = React.useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  return (
    <Wrapper
      isOpen={isOpen}
      onClose={handleClose}
      region={region}
    >
      {isOpen && <Content item={item} onChange={onChange} />}
    </Wrapper>
  );
};

const Content = ({ item, onChange }: Pick<IBlockSettingsProps, 'item' | 'onChange'>) => {
  const { t } = useAdminTranslation();
  const { seasons, tournaments, sessions, onChangeGameType, onChangeLeaderboardType, isRanked, isBattleRoyale, filter, gameType } = useFilters(item.defaultFilters);
  const { handleChange } = usePageBlock<EditableStringPaths, IFortniteLeaderboardBlockData>({
    item,
    onChange,
  });

  React.useEffect(() => {
    const defaultFilters: IFortniteLeaderboardBlockData['defaultFilters'] = {
      ...item.defaultFilters,
    };

    if (defaultFilters.season !== filter.season) {
      defaultFilters.season = filter.season;
    }

    if (defaultFilters.tournament !== filter.tournament) {
      defaultFilters.tournament = filter.tournament;
    }

    if (defaultFilters.session !== filter.session) {
      defaultFilters.session = filter.session;
    }

    if (defaultFilters.gameType !== gameType) {
      defaultFilters.gameType = gameType;
    }

    if (hash(defaultFilters) !== hash(item.defaultFilters)) {
      handleChange('defaultFilters')(defaultFilters);
    }
  }, [filter, item.defaultFilters, gameType]);

  const handleClearDesktopBackground = React.useCallback(() => {
    handleChange('background.image.desktop')('');
  }, [handleChange]);

  const handleClearMobileBackground = React.useCallback(() => {
    handleChange('background.image.mobile')('');
  }, [handleChange]);

  const changeGameType = React.useCallback((newGameType: GameType) => () => {
    if (newGameType === item.defaultFilters.gameType) {
      return;
    }

    const defaultFilters: IFortniteLeaderboardBlockData['defaultFilters'] = {
      ...item.defaultFilters,
      gameType: newGameType,
    };

    if (item.defaultFilters.season) {
      defaultFilters.season = '';
    }

    if (item.defaultFilters.tournament) {
      defaultFilters.tournament = '';
    }

    if (item.defaultFilters.session) {
      defaultFilters.session = '';
    }

    handleChange('defaultFilters')(defaultFilters);
    onChangeGameType(newGameType);
  }, [handleChange, onChangeGameType, item.defaultFilters]);

  const changeLeaderboardType = React.useCallback((leaderboardType: LeaderboardType) => () => {
    if (leaderboardType === item.defaultFilters.leaderboardType) {
      return;
    }

    const defaultFilters: IFortniteLeaderboardBlockData['defaultFilters'] = {
      ...item.defaultFilters,
      leaderboardType,
    };

    if (item.defaultFilters.season) {
      defaultFilters.season = '';
    }

    if (item.defaultFilters.tournament) {
      defaultFilters.tournament = '';
    }

    if (item.defaultFilters.session) {
      defaultFilters.session = '';
    }

    handleChange('defaultFilters')(defaultFilters);
    onChangeLeaderboardType(leaderboardType);
  }, [handleChange, onChangeLeaderboardType, item.defaultFilters]);

  const changeSeason = React.useCallback((option: Record<string, any>) => {
    if (option.value === item.defaultFilters.season) {
      return;
    }

    seasons.onChange(option.value);
  }, [handleChange, seasons.onChange, item.defaultFilters]);

  const changeTournament = React.useCallback((option: Record<string, any>) => {
    if (option.value === item.defaultFilters.tournament) {
      return;
    }

    tournaments.onChange(option.value);
  }, [handleChange, tournaments.onChange, item.defaultFilters]);

  const changeSession = React.useCallback((option: Record<string, any>) => {
    handleChange('defaultFilters.session')(option.value);
    sessions.onChange(option.value);
  }, [handleChange, sessions.onChange]);

  const { isCustomColor } = usePageBlockBackgroundColorOptions(item.background.color);

  const handleChangeBackgroundColor = React.useCallback((value: string) => {
    const newItem: IFortniteLeaderboardBlockData = structuredClone(item);

    newItem.background.color = value;
    if (isCustomColor(value)) {
      newItem.background.customColor = value;
    }

    onChange(newItem);
  }, [item, onChange, isCustomColor]);

  return (
    <Section gap={30}>
      {/* Display */}
      <Section gap={16}>
        <TranslatedText component={SectionTitle} stringKey="ADMIN_LABEL_DISPLAY" />
        <Section gap={15}>
          <Checkbox
            checked={item.title.show}
            labelKey="ADMIN_LABEL_TITLE"
            onCheckedChange={handleChange('title.show')}
            testId="fortniteLeaderboardTitleCheckbox"
          />
          <Checkbox
            checked={item.description.show}
            labelKey="ADMIN_LABEL_DESCRIPTION"
            onCheckedChange={handleChange('description.show')}
            testId="fortniteLeaderboardTDescriptionCheckbox"
          />
        </Section>
      </Section>

      {/* Default Data */}
      <Section gap={16}>
        <TranslatedText component={SectionTitle} stringKey="DEFAULT_DATA" />
        <OptionsToggle>
          <OptionToggle
            onClick={changeLeaderboardType('general')}
            active={!isRanked}
          >
            {t('GENERAL')}
          </OptionToggle>
          <OptionToggle
            onClick={changeLeaderboardType('ranked')}
            active={isRanked}
          >
            {t('RANKED')}
          </OptionToggle>
        </OptionsToggle>

        <OptionsToggle>
          <OptionToggle
            onClick={changeGameType('battle-royale')}
            active={isBattleRoyale}
          >
            {t('BATTLE_ROYALE')}
          </OptionToggle>
          <OptionToggle
            onClick={changeGameType('zero-build')}
            active={!isBattleRoyale}
          >
            {t('ZERO_BUILD')}
          </OptionToggle>
        </OptionsToggle>

        <Section gap={6}>
          <StyledDropdown
            labelKey="SEASON"
            placeholder={t('SELECT_SEASON')}
            isAdmin={true}
            padding="0"
            onChange={changeSeason}
            options={seasons.options}
            value={seasons.selected}
          />
          {
            item.defaultFilters.leaderboardType === 'general' && (
              <>
                <StyledDropdown
                  labelKey="TOURNAMENT"
                  placeholder={t('SELECT_TOURNAMENT')}
                  isAdmin={true}
                  padding="0"
                  onChange={changeTournament}
                  options={tournaments.options}
                  value={tournaments.selected}
                  isDisabled={!seasons.selected}
                />
                <StyledDropdown
                  labelKey="SESSION"
                  placeholder={t('SELECT_SESSION')}
                  isAdmin={true}
                  padding="0"
                  onChange={changeSession}
                  options={sessions.options}
                  value={sessions.selected}
                  isDisabled={!tournaments.selected}
                />
              </>
            )
          }
        </Section>
      </Section>

      {/* Background images */}
      <Section gap={20}>
        <ToggleWrapper>
          <TranslatedText component={SectionTitle} stringKey="ADMIN_SETTINGS_DESIGN_PAGE_BACKGROUND_IMAGE" />
          <ToggleSwitch checked={item.background.image.show} onChange={handleChange('background.image.show')} />
        </ToggleWrapper>
        {
          item.background.image.show && (
            <>
              <UploadZoneWrapper>
                <StyledLabel padding="0" labelKey="ADMIN_LABEL_DESKTOP" />
                <DesktopBackgroundUploadZone
                  imagePreview={item.background.image.desktop}
                  onClearImage={handleClearDesktopBackground}
                  onFileSubmit={handleChange('background.image.desktop')}
                />
              </UploadZoneWrapper>
              <UploadZoneWrapper>
                <StyledLabel padding="0" labelKey="ADMIN_LABEL_MOBILE" />
                <MobileBackgroundUploadZone
                  imagePreview={item.background.image.mobile}
                  onClearImage={handleClearMobileBackground}
                  onFileSubmit={handleChange('background.image.mobile')}
                />
              </UploadZoneWrapper>
            </>
          )
        }

        {/* Background color */}
        <TranslatedText component={SectionTitle} stringKey="ADMIN_LABEL_BACKGROUND_COLOR" />
        <BackgroundColorOptions
          lastCustomColor={item.background.customColor}
          color={item.background.color}
          onChange={handleChangeBackgroundColor}
        />
      </Section>
    </Section>
  );
};

export default BlockSettings;
