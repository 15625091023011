import React from 'react';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin';
import { isMobileLayout } from 'services/device';
import { DynamicTranslationType, useEndUserInlineTextEditorTranslation, useEndUserTranslation } from 'hooks/use-translation';
import BlockSettings from './BlockSettings';
import ImageEditorModal from './ImageEditorModal';
import usePageBlock from 'hooks/use-page-block';
import { IImageAndText, IImageAndTextProps } from './utils';
import {
  BackgroundImage,
  Content,
  Description,
  Label,
  LayoutContainer,
  Title,
  StyledQuickEditOverlay,
  CTAEditorModal,
} from './styles';
import { getAdminBarWidth } from 'services/admin/selectors';
import { PREDEFINED_TOOLBAR_BUTTONS } from 'components/ui/RichTextEditorV2/utils';
import { CTAButton } from 'components/ui/CTAButton';
import { getIsRichTextEditorV3Enabled } from 'services/feature-gate/selectors';

type EditableStringPath =
  | 'label.rawData'
  | 'label.rawDataV2'
  | 'title.rawData'
  | 'title.rawDataV2'
  | 'description.rawData'
  | 'description.rawDataV2'
  | 'cta.text'
  | 'cta.href'
  | 'cta.fontSize'
  | 'cta.variant'
  | 'cta.behavior'
  | 'cta.height.desktop'
  | 'cta.height.mobile';

const ImageAndText: React.FC<IImageAndTextProps> = ({
  onRemove,
  item,
  onEdit,
  pageId,
}) => {
  const isMobile = useSelector(isMobileLayout);
  const isEditing = useSelector(isEditMode);
  const adminBarWidth = useSelector(getAdminBarWidth);
  const isRichTextEditorV3Enabled = useSelector(getIsRichTextEditorV3Enabled);

  const { inlineTextEditorEndUserT } = useEndUserInlineTextEditorTranslation(isEditing);
  const { endUserT } = useEndUserTranslation();

  const shouldShowLocalizedVersion = !isEditing;
  const params = {
    blockId: item.arrayId,
    pageId,
  };

  const localizedLabel = inlineTextEditorEndUserT(DynamicTranslationType.imageAndTextBlockLabel, item.data.label.rawData, params);
  const localizedTitle = inlineTextEditorEndUserT(DynamicTranslationType.imageAndTextBlockTitle, item.data.title.rawData, params);
  const localizedDescription = inlineTextEditorEndUserT(DynamicTranslationType.imageAndTextBlockDescription, item.data.description.rawData, params);
  const localizedCtaText = endUserT([
    DynamicTranslationType.imageAndTextBlockCta,
    params,
  ]) || item.data.cta.text;

  const handleChangeData = React.useCallback((update: IImageAndText) => {
    onEdit({ ...item, data: update });
  }, [item, onEdit]);

  const {
    handleChange,
    handleCloseSettings,
    handleOpenSettings,
    isSettingsOpen,
  } = usePageBlock<EditableStringPath, IImageAndText>({
    item: item.data,
    onChange: handleChangeData,
  });

  const renderLabel = () => {
    if (!item.data.label.show) {
      return null;
    }

    const { rawDataV2 } = item.data.label;
    const useRawDataV2 = Boolean(isRichTextEditorV3Enabled && rawDataV2);
    const data = useRawDataV2 ? rawDataV2 : localizedLabel;
    const key = useRawDataV2 ? 'label.rawDataV2' : 'label.rawData';

    return (
      <Label
        blockTitleKey="ADMIN_LABEL"
        onChange={handleChange(key)}
        data={data}
        useRawDataV2={useRawDataV2}
        alignment={item.data.alignment}
        maxLength={50}
        backgroundColor={item.data.background.color}
        toolbarButtons={PREDEFINED_TOOLBAR_BUTTONS.titleEditor}
        data-testid="imageAndTextLabel"
      />
    );
  };

  const renderTitle = () => {
    const { rawDataV2 } = item.data.title;
    const useRawDataV2 = Boolean(isRichTextEditorV3Enabled && rawDataV2);
    const data = useRawDataV2 ? rawDataV2 : localizedTitle;
    const key = useRawDataV2 ? 'title.rawDataV2' : 'title.rawData';

    return (
      <Title
        blockTitleKey="ADMIN_LABEL_TITLE"
        onChange={handleChange(key)}
        useRawDataV2={useRawDataV2}
        data={data}
        alignment={item.data.alignment}
        maxLength={250}
        toolbarButtons={PREDEFINED_TOOLBAR_BUTTONS.titleEditor}
        data-data-testid="imageAndTextTitle"
        htmlTag="h2"
      />
    );
  };

  const renderDescription = () => {
    if (!item.data.description.show) {
      return null;
    }

    const { rawDataV2 } = item.data.description;
    const useRawDataV2 = Boolean(isRichTextEditorV3Enabled && rawDataV2);
    const data = useRawDataV2 ? rawDataV2 : localizedDescription;
    const key = useRawDataV2 ? 'description.rawDataV2' : 'description.rawData';

    return (
      <Description
        blockTitleKey="ADMIN_LABEL_DESCRIPTION"
        onChange={handleChange(key)}
        useRawDataV2={useRawDataV2}
        data={data}
        alignment={item.data.alignment}
        maxLength={500}
        toolbarButtons={PREDEFINED_TOOLBAR_BUTTONS.bodyEditor}
        data-testid="imageAndTextDescription"
      />
    );
  };

  const renderCTA = React.useCallback(() => {
    if (!item.data.cta.show) {
      return null;
    }

    const cta = {
      ...item.data.cta,
      text: shouldShowLocalizedVersion ? localizedCtaText : item.data.cta.text,
    };

    return (
      <CTAEditorModal
        alignment={item.data.alignment}
        modalTitleKey="ADMIN_LABEL_CALL_TO_ACTION"
        blockTitleKey="ADMIN_LABEL_CALL_TO_ACTION"
        onChangeFontSize={handleChange('cta.fontSize')}
        onChangeVariant={handleChange('cta.variant')}
        onChangeBehavior={handleChange('cta.behavior')}
        onChangeText={handleChange('cta.text')}
        onChangeDesktopHeight={handleChange('cta.height.desktop')}
        onChangeMobileHeight={handleChange('cta.height.mobile')}
        cta={item.data.cta}
      >
        {(text) => (
          <CTAButton
            containerBackgroundColor={item.data.background.color}
            fontSize={cta.fontSize}
            variant={cta.variant}
            height={cta.height}
          >
            {text}
          </CTAButton>
        )}
      </CTAEditorModal>
    );
  }, [
    localizedCtaText,
    item.data.cta,
    item.data.background.color,
    item.data.alignment,
    isEditing,
    handleChange,
    pageId,
    shouldShowLocalizedVersion,
    adminBarWidth,
  ]);

  const backgroundImage = React.useMemo(() => {
    if (isMobile && item.data.background.image.mobile) {
      return item.data.background.image.mobile;
    }

    if (!isMobile && item.data.background.image.desktop) {
      return item.data.background.image.desktop;
    }

    return null;
  }, [
    isMobile,
    item.data.background.image.mobile,
    item.data.background.image.desktop,
  ]);

  return (
    <StyledQuickEditOverlay
      backgroundColor={item.data.background.color}
      onRemove={onRemove}
      titleKey="LABEL_IMAGE_AND_TEXT"
      onEdit={handleOpenSettings}
      testId={`image-and-text-${item.arrayId}`}
    >
      {item.data.background.image.show && backgroundImage && (
        <BackgroundImage
          src={backgroundImage}
          alt="Image and text Background Image"
        />
      )}
      <LayoutContainer layout={item.data.layout}>
        <Content>
          {renderLabel()}
          {renderTitle()}
          {renderDescription()}
          {renderCTA()}
        </Content>
        <ImageEditorModal item={item.data} onChange={handleChangeData} />
      </LayoutContainer>
      <BlockSettings
        isOpen={isSettingsOpen}
        onClose={handleCloseSettings}
        item={item.data}
        onChange={handleChangeData}
      />
    </StyledQuickEditOverlay>
  );
};

export default ImageAndText;
