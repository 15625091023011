import React from 'react';
import BlockSettings from './BlockSettings';
import { IImageGalleryProps, IImageGallery } from './utils';
import { StyledQuickEditOverlay, BackgroundImage, Title, Wrapper } from './styles';
import Gallery from './Gallery';
import usePageBlock from 'hooks/use-page-block';
import { PREDEFINED_TOOLBAR_BUTTONS } from 'components/ui/RichTextEditorV2/utils';
import { ImageGalleryProvider, useImageGalleryContext } from './ImageGalleryContext/ImageGalleryContext';
import { getIsRichTextEditorV3Enabled } from 'services/feature-gate/selectors';
import { useSelector } from 'react-redux';

const ImageGallery: React.FC<IImageGalleryProps> = ({ onEdit, onRemove, item }) => {
  const { backgroundImage, isScrollable, title: localizedTitle } = useImageGalleryContext();
  const isRichTextEditorV3Enabled = useSelector(getIsRichTextEditorV3Enabled);
  const useRawDataV2 = Boolean(isRichTextEditorV3Enabled && item.data.titleV2);
  const title = useRawDataV2 ? item.data.titleV2 : localizedTitle;
  const key = useRawDataV2 ? 'titleV2' : 'title';

  const handleChangeData = (update: IImageGallery) => {
    onEdit({ ...item, data: update });
  };

  const { handleChange, handleCloseSettings, handleOpenSettings, isSettingsOpen } = usePageBlock<keyof typeof item.data, IImageGallery>({
    item: item.data,
    onChange: handleChangeData,
  });

  return (
    <ImageGalleryProvider item={item} onEdit={onEdit}>
      <StyledQuickEditOverlay
        backgroundColor={item.data.background.color}
        onEdit={handleOpenSettings}
        onRemove={onRemove}
        titleKey="ADMIN_LABEL_IMAGE_GALLERY"
        testId={`imageGallery-${item.arrayId}`}
      >
        <Wrapper isScrollable={isScrollable}>
          {Boolean(backgroundImage) && (
            <BackgroundImage
              src={backgroundImage}
              alt="Image Gallery Background Image"
            />
          )}
          <Title
            blockTitleKey="ADMIN_LABEL_TITLE"
            onChange={handleChange(key)}
            data={title}
            useRawDataV2={useRawDataV2}
            data-testid="imageGalleryTitle"
            toolbarButtons={PREDEFINED_TOOLBAR_BUTTONS.titleEditor}
            htmlTag="h2"
          />
          <Gallery />
          <BlockSettings
            isOpen={isSettingsOpen}
            item={item.data}
            onChange={handleChangeData}
            onClose={handleCloseSettings}
          />
        </Wrapper>
      </StyledQuickEditOverlay>
    </ImageGalleryProvider>
  );
};


export const ImageGalleryWrapper: React.FC<IImageGalleryProps> = props => {
  return (
    <ImageGalleryProvider item={props.item} onEdit={props.onEdit}>
      <ImageGallery {...props} />
    </ImageGalleryProvider>
  );
};

export default ImageGalleryWrapper;
