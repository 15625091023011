import React from 'react';
import { IBlockRegion, IShopifyBlockData } from '../utils';
import BlockPanelSettings from './BlockPanelSettings';
import BlockSettings from './BlockSettings';

interface IShopifyBlockSettings {
  isOpen?: boolean;
  item: IShopifyBlockData;
  onChange: (item: IShopifyBlockData) => void
  onClose?: () => void;
  region: IBlockRegion;
}

const ShopifyBlockSettings: React.FC<IShopifyBlockSettings> = ({ item, onChange, region, isOpen = false, onClose }) => {
  const handleClose = React.useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  if (region === 'panel') {
    return <BlockPanelSettings item={item} onChange={onChange} />;
  }

  return <BlockSettings isOpen={isOpen} item={item} onChange={onChange} onClose={handleClose} />;
};

export default ShopifyBlockSettings;
