import React from 'react';
import { IBlockRegion, IShopifyBlockData } from './utils';
import { useSelector } from 'react-redux';
import { isMobileLayout } from 'services/device';

const useShopifyBlockAspectRatio = (item: IShopifyBlockData, region?: IBlockRegion) => {
  const isRenderingOnPanel = region === 'panel';
  const isMobile = useSelector(isMobileLayout);

  return React.useMemo(() => {
    if (isRenderingOnPanel) {
      return item.aspectRatio.mobileEqualDesktop ? item.aspectRatio.desktop : (isMobile ? item.aspectRatio.mobile : item.aspectRatio.desktop);
    }

    if (isMobile) {
      return item.aspectRatio.mobileEqualDesktop ? item.aspectRatio.desktop : item.aspectRatio.mobile;
    }

    return item.aspectRatio.desktop;
  }, [isRenderingOnPanel, isMobile, item.aspectRatio]);
};

export default useShopifyBlockAspectRatio;
