import React from 'react';
import { IFortniteLeaderboardBlockData, IFortniteLeaderboardBlockProps } from './utils';
import usePageBlock from 'hooks/use-page-block';
import Header from './Header';
import { BackgroundImage, StyledQuickEditOverlay } from './styles';
import Leaderboard from './Leaderboard';
import { useSelector } from 'react-redux';
import { isMobileLayout } from 'services/device';

const FortniteLeaderboard: React.FC<IFortniteLeaderboardBlockProps> = ({
  item,
  onEdit,
  onRemove,
  region,
  onOpenBlockSettings,
}) => {
  const blockWrapperRef = React.useRef<HTMLDivElement>(null);
  const isMobile = useSelector(isMobileLayout);
  const handleChangeData = (update: IFortniteLeaderboardBlockData) => {
    onEdit({ ...item, data: update });
  };

  const { handleChange, handleCloseSettings, handleOpenSettings, isSettingsOpen } = usePageBlock<'title.rawData' | 'description.rawData', IFortniteLeaderboardBlockData>({
    item: item.data,
    onChange: handleChangeData,
  });
  const handleOpenBlockSettings = onOpenBlockSettings || handleOpenSettings;

  const backgroundImage = React.useMemo(() => {
    if (!item.data.background.image.show) {
      return undefined;
    }

    if (isMobile && item.data.background.image.mobile) {
      return item.data.background.image.mobile;
    }

    if (!isMobile && item.data.background.image.desktop) {
      return item.data.background.image.desktop;
    }

    return undefined;
  }, [
    isMobile,
    item.data.background.image.mobile,
    item.data.background.image.desktop,
    item.data.background.image.show,
  ]);

  const BlockSettings = React.useMemo(() => {
    if (region === 'panel') {
      return null;
    }

    return React.lazy(() => import('./BlockSettings'));
  }, [region]);

  return (
    <StyledQuickEditOverlay
      ref={blockWrapperRef}
      backgroundColor={item.data.background.color}
      onEdit={handleOpenBlockSettings}
      onRemove={onRemove}
      titleKey="ADMIN_LABEL_LEADERBOARD"
      testId={`fortnite-leaderboard-block-${item.arrayId}`}
      region={region}
    >
      {backgroundImage && <BackgroundImage src={backgroundImage} alt="Fortnite Singles Leaderboard Image" />}
      <Header
        description={item.data.description}
        onChangeDescriptionRaw={handleChange('description.rawData')}
        onChangeTitleRaw={handleChange('title.rawData')}
        title={item.data.title}
        region={region}
      />
      <Leaderboard
        region={region}
        defaultFilters={item.data.defaultFilters}
        blockWrapperRef={blockWrapperRef}
      />
      {
        BlockSettings && (
          <React.Suspense fallback={null}>
            <BlockSettings
              item={item.data}
              onChange={handleChangeData}
              isOpen={isSettingsOpen}
              onClose={handleCloseSettings}
              region={region}
            />
          </React.Suspense>
        )
      }
    </StyledQuickEditOverlay>
  );
};

export default FortniteLeaderboard;
