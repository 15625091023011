import usePageBlock from 'hooks/use-page-block';
import React from 'react';
import { useSelector } from 'react-redux';
import { isMobileLayout } from 'services/device';
import BlockSettings from './BlockSettings';
import { IVideoSpotlight, IVideoSpotlightProps } from './utils';
import { VideoLibraryModalV2 } from 'components/admin2/videoPanels/VideoLibraryModal/VideoLibraryModalV2';
import videoLibraryModalTypes from 'components/modals/videoLibraryModalTypes';
import { IVideo } from 'models';
import { useRealtimeDocument } from 'hooks';
import { isEditMode } from 'services/admin';
import VideoContent from './VideoContent';
import TextContent from './TextContent';
import { BackgroundImage, MainEditor, StyledQuickEditOverlay } from './styles';

type EditableStringPaths = 'videoId';

const VideoSpotlight: React.FC<IVideoSpotlightProps> = ({ item, onEdit, onRemove }) => {
  const isMobile = useSelector(isMobileLayout);
  const isEditing = useSelector(isEditMode);
  const [isVideoLibraryModalOpen, setIsVideoLibraryModalOpen] = React.useState(false);
  const [video, loaded] = useRealtimeDocument<IVideo | null>('video', item.data.videoId);
  const isDefaultBlock = !item.data.videoId;

  const handleChangeData = React.useCallback((update: IVideoSpotlight) => {
    onEdit({ ...item, data: update });
  }, [item, onEdit]);

  const {
    handleChange,
    handleCloseSettings,
    handleOpenSettings,
    isSettingsOpen,
  } = usePageBlock<EditableStringPaths, IVideoSpotlight>({
    item: item.data,
    onChange: handleChangeData,
  });

  const backgroundImage = React.useMemo(() => {
    if (isMobile && item.data.background.image.mobile) {
      return item.data.background.image.mobile;
    }

    if (!isMobile && item.data.background.image.desktop) {
      return item.data.background.image.desktop;
    }

    return null;
  }, [
    isMobile,
    item.data.background.image.mobile,
    item.data.background.image.desktop,
  ]);

  const handleOpenVideoLibraryModal = React.useCallback(() => {
    if (!isEditing) {
      return;
    }
    setIsVideoLibraryModalOpen(true);
  }, [isEditing]);

  const handleCloseVideoLibraryModal = React.useCallback(() => {
    setIsVideoLibraryModalOpen(false);
  }, []);

  const handleSelectVideo = React.useCallback((newVideo: IVideo) => {
    handleChange('videoId')(newVideo._id);
    handleCloseVideoLibraryModal();
  }, [handleChange, handleCloseVideoLibraryModal]);

  const hasTextContent = React.useMemo(() => {
    return (
      item.data.uploadDate.show ||
      item.data.title.show ||
      item.data.description.show ||
      item.data.tags.show
    );
  }, [
    item.data.uploadDate.show,
    item.data.title.show,
    item.data.description.show,
    item.data.tags.show,
  ]);

  // If the video is not found, don't render block
  // We need to check item.data.videoId because we render default texts
  if ((!isDefaultBlock && loaded && !video) || video?.archived) {
    return null;
  }

  return (
    <StyledQuickEditOverlay
      backgroundColor={item.data.background.color}
      testId={`video-spotlight-${item.arrayId}`}
      titleKey="LABEL_VIDEO_SPOTLIGHT"
      onEdit={handleOpenSettings}
      onRemove={onRemove}
    >
      {item.data.background.image.show && backgroundImage && (loaded || isDefaultBlock) && (
        <BackgroundImage
          src={backgroundImage}
          alt="Video Spotlight Background Image"
        />
      )}
      <MainEditor
        isEditing={isEditing}
        hasTextContent={hasTextContent}
        layout={item.data.layout}
        borderStyle="dashed"
        blockTitleKey="ADMIN_LABEL_VIDEO"
        keepBorder={isSettingsOpen}
        data-testid="video-spotlight-editor-modal"
        onClick={handleOpenVideoLibraryModal}
      >
        <VideoContent item={item.data} arrayId={item.arrayId} loaded={loaded} video={video} />
        <TextContent item={item.data} loaded={loaded} video={video} />
      </MainEditor>
      {
        isVideoLibraryModalOpen && (
          <VideoLibraryModalV2
            onSelectItem={handleSelectVideo}
            type={videoLibraryModalTypes.OFFLINE_CONTENT}
            onClose={handleCloseVideoLibraryModal}
            onDismiss={handleCloseVideoLibraryModal}
          />
        )
      }
      <BlockSettings
        isOpen={isSettingsOpen}
        onClose={handleCloseSettings}
        item={item.data}
        onChange={handleChangeData}
      />
    </StyledQuickEditOverlay>
  );
};

export default VideoSpotlight;
