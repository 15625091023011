import { IFortniteLeaderboardBlock } from 'services/app/models/ILandingPageContent';
import { getDefaultEditorState, getDefaultTextNode } from 'components/ui/RichTextEditorV3/getDefaultEditorState';
import { MAESTRO_BLACK } from 'style/constants';
import { IFortniteLeaderboardItem } from 'models/IFortniteLeaderboard';

export type IBlockRegion = 'landingPageBlock' | 'panel';
export type GameType = 'battle-royale' | 'zero-build';
export type LeaderboardType = 'general' | 'ranked';

export interface IFortniteLeaderboardBlockProps {
  index?: number;
  item: IFortniteLeaderboardBlock;
  onEdit: (item: IFortniteLeaderboardBlock) => void;
  onOpenBlockSettings?: () => void;
  onRemove: () => void;
  region: IBlockRegion;
}

export interface IFortniteLeaderboardBlockData {
  background: {
    color: string;
    customColor: string;
    image: {
      desktop: string;
      mobile: string;
      show: boolean;
    }
  }
  defaultFilters: {
    gameType: GameType;
    leaderboardType: LeaderboardType;
    season: string;
    session: string;
    tournament: string;
  }
  description: {
    rawData: string;
    show: boolean;
  };
  title: {
    rawData: string;
    show: boolean;
  }
}

export const DEFAULT_FORTNITE_LEADERBOARD: IFortniteLeaderboardBlockData = {
  background: {
    color: 'surface1',
    customColor: MAESTRO_BLACK,
    image: {
      desktop: '',
      mobile: '',
       show: false,
    },
  },
  title: {
    rawData: getDefaultEditorState([
      getDefaultTextNode({
        text: 'Lorem ipsum dolor sit amet',
        textFormatType: 'bold',
        fontSize: 60,
      }),
    ]),
    show: true,
  },
  defaultFilters: {
    leaderboardType: 'general',
    season: '',
    session: '',
    tournament: '',
    gameType: 'battle-royale',
  },
  description: {
    rawData: getDefaultEditorState([
      getDefaultTextNode({
        text: 'Lorem ipsum dolor sit amet consectetur. Sed nisl lectus odio ac vitae ornare integer. Vel sit quam ac semper pellentesque. Ullamcorper justo vulputate ultricies volutpat nisl neque tincidunt. Hendrerit habitant lorem proin sed.',
        fontSize: 20,
      }),
    ]),
    show: true,
  },
};

export const getLastUpdatedDateString = (timestamp: number): string => {
  // Format the date part (month/day/year)
  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
  const dateString = dateFormatter.format(timestamp);

  // Format the time part (hours:minutes AM/PM)
  const timeFormatter = new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
  const timeString = timeFormatter.format(timestamp);

  // Get the timezone offset in hours and minutes
  const offset = -new Date(timestamp).getTimezoneOffset(); // in minutes
  const offsetHours = Math.floor(offset / 60);
  const offsetMinutes = offset % 60;
  const offsetSign = offset >= 0 ? '+' : '-';
  const formattedOffset = `GMT${offsetSign}${Math.abs(offsetHours).toString().padStart(2, '0')}${Math.abs(offsetMinutes).toString().padStart(2, '0')}`;

  // Combine the formatted date, time, and timezone offset
  const formattedString = `${dateString}, ${timeString} ${formattedOffset}`;

  return formattedString;
};

export type DropdownOption = {
  label: string;
  value: string;
};

export const DEFAULT_LEADERBOARD_ITEMS: IFortniteLeaderboardItem[] = [
  {
    eliminations: 0,
    matches: 0,
    players: [{ displayName: '-' }],
    points: 0,
    rank: 1,
    royals: 0,
    teamId: 'teamId1',
  },
  {
    eliminations: 0,
    matches: 0,
    players: [{ displayName: '-' }],
    points: 0,
    rank: 2,
    royals: 0,
    teamId: 'teamId2',
  },
  {
    eliminations: 0,
    matches: 0,
    players: [{ displayName: '-' }],
    points: 0,
    rank: 3,
    royals: 0,
    teamId: 'teamId3',
  },
];
