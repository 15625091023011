import React, { createContext, FC, PropsWithChildren, useContext } from 'react';
import { IBlockRegion, IShopifyBlockData } from './utils';
import useShopifyBlock from './use-shopify-block';

export const ShopifyBlockContext = createContext<ReturnType<typeof useShopifyBlock>>({} as any);

interface IShopifyBlockProvider extends PropsWithChildren {
  item: IShopifyBlockData;
  onChangeCollectionProductsIds: (ids: string[]) => void;
  onChangeMultipleProductsIds: (ids: string[]) => void;
  region: IBlockRegion;
}

export const ShopifyBlockProvider: FC<IShopifyBlockProvider> = ({ children, item, region, onChangeCollectionProductsIds, onChangeMultipleProductsIds }) => {
  return (
    <ShopifyBlockContext.Provider
      value={useShopifyBlock(item, region, onChangeCollectionProductsIds, onChangeMultipleProductsIds)}
    >
      {children}
    </ShopifyBlockContext.Provider>
  );
};


export const useShopifyBlockContext = () => useContext(ShopifyBlockContext);
