import { getDefaultRichTextEditorData } from 'utils';
import { MAESTRO_BLACK } from 'style/constants';
import { OverflowBehavior } from '../../admin-bridge/EditorModal/ModalComponents/OptionRow';
import { IProduct } from 'services/shopify/models';
import { IShopifyBlock } from 'services/app/models/ILandingPageContent';
import { getDefaultEditorState, getDefaultTextNode } from 'components/ui/RichTextEditorV3/getDefaultEditorState';

export type IBlockRegion = 'landingPageBlock' | 'panel';

export interface IShopifyBlockProps {
  index?: number;
  item: IShopifyBlock;
  onEdit: (item: IShopifyBlock) => void;
  onOpenBlockSettings?: () => void;
  onRemove: () => void;
  region: IBlockRegion;
}

export type AspectRatio = `${number} / ${number}`;

export const ASPECT_RATIO_LABEL_MAP: Record<AspectRatio, string> = {
  '1 / 1': '1:1',
  '4 / 3': '4:3',
  '3 / 2': '3:2',
  '16 / 9': '16:9',
  '21 / 9': '21:9',
  '3 / 4': '3:4',
  '2 / 3': '2:3',
  '9 / 16': '9:16',
};

export const ASPECT_RATIO_OPTIONS: { label: string; value: AspectRatio; }[] = Object.keys(ASPECT_RATIO_LABEL_MAP).map((key) => ({
  label: ASPECT_RATIO_LABEL_MAP[key as AspectRatio],
  value: key as AspectRatio,
}));

export type ProductViewKeys = 'collection' | 'singleProduct' | 'multipleProducts';

export const PRODUCT_VIEW_LABEL_MAP: Record<ProductViewKeys, string> = {
  collection: 'Collection',
  singleProduct: 'Single Product',
  multipleProducts: 'Multiple Products',
};

export const PRODUCT_VIEW_OPTIONS: { label: string; value: ProductViewKeys }[] = [
  {
    value: 'collection',
    label: PRODUCT_VIEW_LABEL_MAP.collection,
  },
  {
    label: PRODUCT_VIEW_LABEL_MAP.singleProduct,
    value: 'singleProduct',
  },
  {
    label: PRODUCT_VIEW_LABEL_MAP.multipleProducts,
    value: 'multipleProducts',
  },
];

export interface IShopifyBlockData {
  aspectRatio: {
    desktop: AspectRatio;
    mobile: AspectRatio;
    mobileEqualDesktop: boolean;
  }
  background: {
    color: string;
    customColor: string;
    image: {
      desktop: string;
      mobile: string;
      show: boolean;
    }
  }
  collectionProductsIds?: string[];
  multipleProductsIds?: string[];
  overflowBehavior: {
    desktop: OverflowBehavior;
    mobile: OverflowBehavior;
  }
  productsPerRow: number;
  productView: ProductViewKeys;
  returnPolicy: string;
  shopifyCollectionHandle: string;
  showProductCta: boolean;
  showProductDescription: boolean;
  showProductName: boolean;
  showProductPrice: boolean;
  singleProductId: string;
  termsOfUse: string;
  titleRaw: string;
  titleRawV2?: string;
}

export const MOCK_PRODUCT_ID = 'shopify-block-mock-product-id';
export const MOCK_PRODUCT: IProduct = {
  availableForSale: true,
  title: '',
  description: '',
  variants: {
    edges: [
      {
        node: {
          availableForSale: true,
          id: 'shopify-block-mock-product-variant-id',
          price: {
            amount: '000',
            currencyCode: 'USD',
          },
          image: {
            altText: '',
            id: 'shopify-block-mock-product-image-id',
            url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII=',
          },
          requiresShipping: false,
          selectedOptions: [],
          title: 'Lorem ipsum dolor sit amet consectetur.',
        },
      },
    ],
  },
  handle: 'lorem-ipsum-dolor-sit-amet-consectetur',
  id: MOCK_PRODUCT_ID,
  images: {
    edges: [],
  },
  options: [],
};

export const MOCK_PRODUCTS: IProduct[] = [
  {
    availableForSale: true,
    title: 'Maestro Label Sweater',
    description: 'Elevate your casual wardrobe with our Maestro Label Sweater Sweater adorned with a subtle purple label, seamlessly blending comfort and style',
    variants: {
      edges: [
        {
          node: {
            availableForSale: true,
            id: 'shopify-block-mock-product-variant-id',
            price: {
              amount: '39.99',
              currencyCode: 'USD',
            },
            image: {
              altText: '',
              id: 'shopify-block-mock-product-image-id',
              url: 'https://dev.static.maestro.io/media/6232780288d4500027a054cb/656a5e89d1f3cb00a74644b4.jpg',
            },
            requiresShipping: true,
            selectedOptions: [],
            title: 'Maestro Label Sweater',
          },
        },
      ],
    },
    handle: 'maestro-label-sweater',
    id: MOCK_PRODUCT_ID,
    images: {
      edges: [
        {
          node: {
            altText: 'hehehe',
            id: 'eheheh123',
            url: 'https://dev.static.maestro.io/media/6232780288d4500027a054cb/656a5e89d1f3cb00a74644b4.jpg',
          },
        },
      ],
    },
    options: [],
  },
  {
    availableForSale: true,
    title: 'Maestro Center Logo Tote Bag',
    description: 'Make a bold statement with our sleek Maestro Center Logo Tote Bag, accentuated by a striking center logo for a touch of modern elegance and versatility.',
    variants: {
      edges: [
        {
          node: {
            availableForSale: true,
            id: 'shopify-block-mock-product-variant-id',
            price: {
              amount: '19.99',
              currencyCode: 'USD',
            },
            image: {
              altText: '',
              id: 'shopify-block-mock-product-image-id',
              url: 'https://dev.static.maestro.io/media/6232780288d4500027a054cb/656a6164c4537800a70436cd.jpg',
            },
            requiresShipping: true,
            selectedOptions: [],
            title: 'Maestro Center Logo Tote Bag',
          },
        },
      ],
    },
    handle: 'maestro-center-logo-tote-bag',
    id: MOCK_PRODUCT_ID,
    images: {
      edges: [
        {
          node: {
            altText: 'hehehe',
            id: 'eheheh123',
            url: 'https://dev.static.maestro.io/media/6232780288d4500027a054cb/656a6164c4537800a70436cd.jpg',
          },
        },
      ],
    },
    options: [],
  },
  {
    availableForSale: true,
    title: 'Maestro Baseball Cap',
    description: 'Stay effortlessly cool under the sun with our Maestro Baseball Cap, a perfect blend of style and sun protection for your casual and sporty looks',
    variants: {
      edges: [
        {
          node: {
            availableForSale: true,
            id: 'shopify-block-mock-product-variant-id',
            price: {
              amount: '24.99',
              currencyCode: 'USD',
            },
            image: {
              altText: '',
              id: 'shopify-block-mock-product-image-id',
              url: 'https://dev.static.maestro.io/media/6232780288d4500027a054cb/656a62d354da6300a77cb224.jpg',
            },
            requiresShipping: true,
            selectedOptions: [],
            title: 'Maestro Baseball Cap',
          },
        },
      ],
    },
    handle: 'maestro-baseball-cap',
    id: MOCK_PRODUCT_ID,
    images: {
      edges: [
        {
          node: {
            altText: 'hehehe',
            id: 'eheheh123',
            url: 'https://dev.static.maestro.io/media/6232780288d4500027a054cb/656a62d354da6300a77cb224.jpg',
          },
        },
      ],
    },
    options: [],
  },
  {
    availableForSale: true,
    title: 'Maestro Do What You Love Sweater',
    description: 'Wrap yourself in inspiration with our Maestro \'Do What You Love\' Sweater, a cozy and motivational essential that blends comfort with a positive message.',
    variants: {
      edges: [
        {
          node: {
            availableForSale: true,
            id: 'shopify-block-mock-product-variant-id',
            price: {
              amount: '39.99',
              currencyCode: 'USD',
            },
            image: {
              altText: '',
              id: 'shopify-block-mock-product-image-id',
              url: 'https://dev.static.maestro.io/media/6232780288d4500027a054cb/656a62e1693ab300a677288f.jpg',
            },
            requiresShipping: true,
            selectedOptions: [],
            title: 'Maestro Do What You Love Sweater',
          },
        },
      ],
    },
    handle: 'maestro-do-what-you-love-sweater',
    id: MOCK_PRODUCT_ID,
    images: {
      edges: [
        {
          node: {
            altText: 'hehehe',
            id: 'eheheh123',
            url: 'https://dev.static.maestro.io/media/6232780288d4500027a054cb/656a62e1693ab300a677288f.jpg',
          },
        },
      ],
    },
    options: [],
  },
  {
    availableForSale: true,
    title: 'Maestro Original Baseball Cap',
    description: 'Top off your casual look with our Maestro Original Baseball Cap, a versatile accessory that adds a touch of timeless style to any outfit.',
    variants: {
      edges: [
        {
          node: {
            availableForSale: true,
            id: 'shopify-block-mock-product-variant-id',
            price: {
              amount: '24.99',
              currencyCode: 'USD',
            },
            image: {
              altText: '',
              id: 'shopify-block-mock-product-image-id',
              url: 'https://dev.static.maestro.io/media/6232780288d4500027a054cb/656a63a354da6300a77cb225.jpg',
            },
            requiresShipping: true,
            selectedOptions: [],
            title: 'Maestro Original Baseball Cap',
          },
        },
      ],
    },
    handle: 'maestro-og-baseball-cap',
    id: MOCK_PRODUCT_ID,
    images: {
      edges: [
        {
          node: {
            altText: 'hehehe',
            id: 'eheheh123',
            url: 'https://dev.static.maestro.io/media/6232780288d4500027a054cb/656a63a354da6300a77cb225.jpg',
          },
        },
      ],
    },
    options: [],
  },
  {
    availableForSale: true,
    title: 'Maestro Logo Sweater',
    description: 'Elevate your casual wardrobe with our Black Maestro Logo Sweater, featuring a sleek design that seamlessly combines comfort and style, making a bold statement with every wear.',
    variants: {
      edges: [
        {
          node: {
            availableForSale: true,
            id: 'shopify-block-mock-product-variant-id',
            price: {
              amount: '39.99',
              currencyCode: 'USD',
            },
            image: {
              altText: '',
              id: 'shopify-block-mock-product-image-id',
              url: 'https://dev.static.maestro.io/media/6232780288d4500027a054cb/656a63b054da6300a77cb226.jpg',
            },
            requiresShipping: true,
            selectedOptions: [],
            title: 'Maestro Logo Sweater',
          },
        },
      ],
    },
    handle: 'maestro-logo-sweater',
    id: MOCK_PRODUCT_ID,
    images: {
      edges: [
        {
          node: {
            altText: 'hehehe',
            id: 'eheheh123',
            url: 'https://dev.static.maestro.io/media/6232780288d4500027a054cb/656a63b054da6300a77cb226.jpg',
          },
        },
      ],
    },
    options: [],
  },
  {
    availableForSale: true,
    title: 'Maestro Corner Logo Tote Bag',
    description: 'Make a statement in sophistication with our Maestro Corner Logo Tote Bag, showcasing an elegant design where luxury meets functionality at every corner',
    variants: {
      edges: [
        {
          node: {
            availableForSale: true,
            id: 'shopify-block-mock-product-variant-id',
            price: {
              amount: '19.99',
              currencyCode: 'USD',
            },
            image: {
              altText: '',
              id: 'shopify-block-mock-product-image-id',
              url: 'https://dev.static.maestro.io/media/6232780288d4500027a054cb/656a64d654da6300a77cb227.jpg',
            },
            requiresShipping: true,
            selectedOptions: [],
            title: 'Maestro Corner Logo Tote Bag',
          },
        },
      ],
    },
    handle: 'maestro-cornerr-logo-tote-bag',
    id: MOCK_PRODUCT_ID,
    images: {
      edges: [
        {
          node: {
            altText: 'hehehe',
            id: 'eheheh123',
            url: 'https://dev.static.maestro.io/media/6232780288d4500027a054cb/656a64d654da6300a77cb227.jpg',
          },
        },
      ],
    },
    options: [],
  },
  {
    availableForSale: true,
    title: 'Maestro Center Logo Sweater',
    description: 'Indulge in contemporary style and comfort with our Maestro Center Logo Sweater, where the iconic logo takes center stage, creating a sleek and fashionable statement piece.',
    variants: {
      edges: [
        {
          node: {
            availableForSale: true,
            id: 'shopify-block-mock-product-variant-id',
            price: {
              amount: '39.99',
              currencyCode: 'USD',
            },
            image: {
              altText: '',
              id: 'shopify-block-mock-product-image-id',
              url: 'https://dev.static.maestro.io/media/6232780288d4500027a054cb/656a64e054da6300a77cb228.jpg',
            },
            requiresShipping: true,
            selectedOptions: [],
            title: 'Maestro Center Logo Sweater',
          },
        },
      ],
    },
    handle: 'maestro-center-logo-sweater',
    id: MOCK_PRODUCT_ID,
    images: {
      edges: [
        {
          node: {
            altText: 'hehehe',
            id: 'eheheh123',
            url: 'https://dev.static.maestro.io/media/6232780288d4500027a054cb/656a64e054da6300a77cb228.jpg',
          },
        },
      ],
    },
    options: [],
  },
];

export const DEFAULT_SHOPIFY: IShopifyBlockData = {
  background: {
    color: 'surface1',
    customColor: MAESTRO_BLACK,
    image: {
      desktop: '',
      mobile: '',
      show: false,
    },
  },
  overflowBehavior: {
    desktop: 'slider',
    mobile: 'slider',
  },
  productsPerRow: 4,
  aspectRatio: {
    desktop: '1 / 1',
    mobile: '1 / 1',
    mobileEqualDesktop: true,
  },
  showProductCta: true,
  showProductDescription: false,
  showProductName: true,
  showProductPrice: true,
  titleRaw: getDefaultRichTextEditorData({
    text: 'Shop Our Collection',
    typographicalEmphasis: 'BOLD',
    fontSize: 20,
  }),
  titleRawV2: getDefaultEditorState([
    getDefaultTextNode({
      text: 'Shop Our Collection',
      textFormatType: 'bold',
      fontSize: 20,
    }),
  ]),
  shopifyCollectionHandle: '',
  singleProductId: '',
  multipleProductsIds: [],
  collectionProductsIds: [],
  productView: 'multipleProducts',
  returnPolicy: '',
  termsOfUse: '',
};
